import 'magnific-popup';
import 'owl.carousel';
import moment from 'moment';

jQuery( document ).ready( function ( $ ) {
	// Nav
	$( '#header .hamburger' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).toggleClass( 'is-active' );
		$( '#nav-collapse' ).slideToggle();
	} );

	$( '#header nav>ul>li.local>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );
		$( 'html, body' ).animate( {
			scrollTop: parseInt( $( href ).offset().top )
		}, 1500 );
	} );

	// Music
	$( '#music .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="fas fa-arrow-left" aria-hidden="true"></i><span class="screen-reader-text">Prev</span>',
			'<i class="fas fa-arrow-right" aria-hidden="true"></i><span class="screen-reader-text">Next</span>'
		]
	} );

	// Tour
	$.ajax( {
		url: 'https://rest.bandsintown.com/artists/I%20Am%20They/events?app_id=45PRESS_IAMTHEY',
		method: 'GET',
		dataType: 'json',
		error: () => {
		},
		success: data => {
			const events = $( '#events' );
			let html = '';
			if ( data.length ) {

				if ( data.length > 10 ) {
					$( '#tour .more' ).show();
				}

				for ( let event of data ) {
					const region = event.venue.region !== null && event.venue.region !== '' ? event.venue.region : event.venue.country;
					const location = event.venue.city + ', ' + region;
					html += '<div data-bit-id="' + event.id + '">';
					html += '<div class="event-inner">';
					html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM DD' ) + '</div>';
					html += '<div class="event-location">' + location + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-links">';
					for ( let offer of event.offers ) {
						if ( offer.type === 'Tickets' ) {
							html += '<a href="' + offer.url + '" target="_blank" class="btn">' + offer.type + '</a>';
						}
					}
					html += '<a href="' + event.url + '" target="_blank" class="btn">RSVP</a>';
					html += '</div>';
					html += '</div>';
					html += '</div>';
				}
				events.html( html );
			} else {
				events.html( 'No upcoming events.' );
			}
		}
	} );

	$( '#tour .more>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '#events>div' ).fadeIn();
	} );

	// Videos
	$( '#videos .owl-carousel' ).owlCarousel( {
		items: 3,
		loop: true,
		margin: 30,
		dots: false,
		nav: true,
		navText: [
			'<i class="fas fa-arrow-left" aria-hidden="true"></i><span class="screen-reader-text">Prev</span>',
			'<i class="fas fa-arrow-right" aria-hidden="true"></i><span class="screen-reader-text">Next</span>'
		],
		responsive: {
			0: {
				items: 1,
				margin: 0,
			},
			768: {
				items: 2,
				margin: 30
			},
			992: {
				items: 3,
				margin: 30
			}
		}
	} );

	$( '#videos .owl-item a' ).on( 'click', function ( e ) {
		e.preventDefault();
		const embed_url = $( this ).attr( 'data-embed-url' );
		const title = $( this ).find( 'span' ).text();

		const video_player = $( '#video_player' );

		video_player.find( 'iframe' ).attr( 'src', embed_url );
		video_player.find( 'span' ).text( title );
	} );

	// About
	$( '.short-bio .btn' ).magnificPopup( {
		type: 'inline',
		closeBtnInside: false,
		midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
	} );

	// Newsletter
	$( '#newsletter form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const data = $( this ).serialize();
		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				$( '#newsletter form' ).html( '<div class="thanks">Thanks for signing up!</div>' );
			},
			error: function ( err ) {
				alert( 'An error has occurred!' );
			}
		} );
	} );
} );